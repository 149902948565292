import * as React from 'react';
import {List, Datagrid, DateField, NumberField, Pagination, TextField, BooleanField} from 'react-admin';

import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';

import { InteractionSidebar } from './interactionsFilter';


const SocialNetworkField = ({ record = {}, source }) => {
  switch (record[source]) {
    case 'instagram':
      return (<InstagramIcon />);
    case 'twitter':
      return (<TwitterIcon />);
    default:
      return (<span>{record[source]}</span>);
  }
}

const PostPagination = props => (
    <Pagination rowsPerPageOptions={[10, 50, 100]} {...props} />
);

const InteractionList = (props) => (
  <List
    {...props}
    bulkActionButtons={false}
    title="Unilever Interactions"
    aside={ <InteractionSidebar /> }
    sort={{ field: 'interaction_date', order: 'DESC' }}
    pagination={<PostPagination />}
    perPage={50}
  >
    <Datagrid>
      <SocialNetworkField source='network' />
      <TextField source='username' />
      <BooleanField source='verified' />
      <NumberField source='followers' />
      <TextField label='Influencer By Followers?' source='influencer_by_followers_criterion' />
      <BooleanField label='Influencer By Engagement?' source='influencer_by_engagement_criterion' />
      <DateField source='interaction_date' showTime locales="pt-BR"/>
    </Datagrid>
  </List>
);

export {InteractionList};

