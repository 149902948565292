import * as React from 'react';
import { Card as MuiCard, CardContent, withStyles } from '@material-ui/core';
import { FilterList, FilterListItem } from 'react-admin';

const Card = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: -1, // display on the left rather than on the right of the list
            width: '15em',
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))(MuiCard);


const NetworkFilter = () => (
  <FilterList label='Social Network'>
    <FilterListItem
      label='Instagram'
      value={{ network: 'instagram' }}
    />
    <FilterListItem
      label='Twitter'
      value={{ network: 'twitter' }}
    />
  </FilterList>
);


const EngagementFilter = () => (
  <FilterList label='Engagement Criterion'>
    <FilterListItem
      label='Enable'
      value={{ influencers_by_engagement: 'yes' }}
    />
  </FilterList>
);


const OnlyEngagementFilter = () => (
  <FilterList label='Only Engagement'>
    <FilterListItem
      label='Yes'
      value={{ influencers_by_engagement: 'only' }}
    />
  </FilterList>
);


const InteractionSidebar = () => (
  <Card>
    <CardContent>
      <NetworkFilter />
      <EngagementFilter />
      <OnlyEngagementFilter />
    </CardContent>
  </Card>
);

export { InteractionSidebar };
