import * as React from 'react';
import { Admin, AppBar, Resource, Layout } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import drfProvider, { tokenAuthProvider, fetchJsonWithAuthToken } from 'ra-data-django-rest-framework';
import './App.css';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import {InteractionList} from './interactions';

import logo from './twist.png';

const authProvider = tokenAuthProvider();
const dataProvider = drfProvider("", fetchJsonWithAuthToken);


const theme = createMuiTheme({
  "palette": {
    "common": {
      "black": "rgba(33, 20, 81, 1)",
      "white": "#fff"
    },
    "background": {
      "paper": "#fff",
      "default": "#fafafa"
    },
    "secondary": {
      "light": "rgba(98, 207, 255, 1)",
      "main": "rgba(0, 158, 226, 1)",
      "dark": "rgba(0, 112, 176, 1)",
      "contrastText": "#fff"
    },
    "primary": {
      "light": "rgba(229, 0, 66, 1)",
      "main": "rgba(229, 0, 66, 1)",
      "dark": "rgba(229, 0, 66, 1)",
      "contrastText": "#fff"
    },
    "error": {
      "light": "#e57373",
      "main": "#f44336",
      "dark": "#d32f2f",
      "contrastText": "#fff"
    },
    "text": {
      "primary": "rgba(0, 0, 0, 0.87)",
      "secondary": "rgba(0, 0, 0, 0.54)",
      "disabled": "rgba(0, 0, 0, 0.38)",
      "hint": "rgba(0, 0, 0, 0.38)"
    }
  },
  overrides: {
    RaLogin: {
      main: {
          backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundColor:"rgba(98, 207, 255, 1)", 
      }
    }
  }
})

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1
    },
    logo: {
        maxHeight: "28px",
        marginRight: "2em",
    }
});


function MyAppBar(props) {
    const classes = useStyles();
    return (
        <AppBar {...props}>
            <img src={logo} className={classes.logo} alt="twist" />
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
        </AppBar>
    );
}


function MyLayout(props) {
  return (
    <Layout {...props}
      sidebar={() => null}
      appBar={MyAppBar}
    />
  );
}


function App() {
  return (
    <Admin dataProvider={dataProvider} authProvider={authProvider}
      disableTelemetry layout={MyLayout} theme={theme}
    >
      <Resource
        name='interactions'
        title="Interactions"
        list={InteractionList}
      />
    </Admin>
  );
}

export default App;
